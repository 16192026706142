import { roles } from '@/enums'
import { useCurrentRoleStore } from '~/store/currentRole'

export default defineNuxtRouteMiddleware((to, _from) => {
  const user = useSupabaseUser()
  const currentRoleStore = useCurrentRoleStore()
  const schoolId = currentRoleStore.getCurrentRole().schoolId
  const isAdmin = user.value?.user_metadata.schoolRoles?.find(
    (schoolRole: SchoolRole) =>
      schoolRole.schoolId === schoolId && schoolRole.role === roles.ADMIN,
  )
  if (!isAdmin) {
    return navigateTo('/dashboard')
  }
})
